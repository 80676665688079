import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCuArbhezW_RvaNt7vOfIJpKifTV6u0Teo",
  authDomain: "turnkeynews-c7d31.firebaseapp.com",
  projectId: "turnkeynews-c7d31",
  storageBucket: "turnkeynews-c7d31.appspot.com",
  messagingSenderId: "1003738484358",
  appId: "1:1003738484358:web:00229d6d76e330475d8b07",
  measurementId: "G-K0GPSEWDDY"
};


    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
